import { Outlet, Link } from "react-router-dom";
import { DarkModeProvider } from '../DarkModeContext';
import DarkModeToggle from '../DarkModeToggle';
import { useState } from 'react';

function Layout() {

    var item = "text-center flex-1 px-8 py-4 hover:bg-pink-800 hover:bg-opacity-30 hover:bg-white transition-all duration-200";
    const [isAlgoMenuOpen, setAlgoMenuOpen] = useState(false);
    return (
      <>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
        <DarkModeProvider className="flex">
        <div className="min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100">

          <div className= "sticky top-0 z-50">
          <div className=" text-white bg-gradient-to-r from-Template-0 to-Template-1 text-center">
            <div className="text-2xl">Samuel Weißenbacher</div>
          </div>
          <div className=" text-white flex flex-wrap flex-row bg-gradient-to-r from-Template-0 to-Template-1">
            <Link className={item} to="/">Homepage</Link>
            <Link className={item} to="/projects">Meine Projekte</Link>
            <Link className={item} to="/books">Bücher und Lesezeichen</Link>
            <Link className={item} to="/python">Symbolische Bibliothek in Python</Link>
            

            <div className="relative">
                  <button
                    className={item}
                    onMouseEnter={() => setAlgoMenuOpen(true)}
                    onMouseLeave={() => setAlgoMenuOpen(false)}
                  >
                    Algorithmen
                  </button>
                  {isAlgoMenuOpen && (
                    <div
                      className="flex flex-col absolute  left-0 w-full bg-gradient-to-r from-Template-0 to-Template-1"
                      onMouseEnter={() => setAlgoMenuOpen(true)}
                      onMouseLeave={() => setAlgoMenuOpen(false)}
                    >
                      <Link className={item} to="/bubblesort">Bubblesort</Link>
                      <Link className={item} to="/quicksort">Quicksort</Link>
                      <Link className={item} to="/heapsort">Heapsort</Link>
                    </div>
                  )}
                </div>

            <a className={item} href="https://samuelwebserver.ddns.net/owncloud" target="_blank" rel="noopener noreferrer">Owncloud</a>
            <DarkModeToggle/>
          </div></div>
            <Outlet/>
          </div>
          <div>
          <div className="text-white flex flex-wrap flex-row bg-gradient-to-r from-Template-0 to-Template-1">
            <Link className={item} to="https://www.instagram.com/samuel.weissenbacher/"><div className="fa fa-instagram"/></Link>
            <Link className={item} to="https://gitlab.com/SamuelEliasW/projects_weis"><div className="fa fa-gitlab"/></Link>
            <div className={item}>samuel.weissenbacher@gmx.net</div>
          </div>  
        </div>
        </DarkModeProvider>
      </>
    );
  }
  
  export default Layout;
  