import { useDarkMode } from '../DarkModeContext';
import useIsVisible from '../isVisible';
import { useRef } from "react";

function Books() {
    
  const { isDarkMode } = useDarkMode();

  const refs = [useRef(), useRef(), useRef()];
  const isVisibleStates = 
    [ 
    useIsVisible(refs[0]), 
    useIsVisible(refs[1]), 
    useIsVisible(refs[2])
    ];
  
  const commonStyles = `flex-1 px-8 py-4 transition-opacity ease-in duration-700`;

  const div1 = (isVisible, isDarkMode) => `
    ${commonStyles} 
    ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'} 
    ${isVisible ? 'opacity-100' : 'opacity-0'}
  `;

  return (
    <>
    <div ref={refs[0]} class={div1(isVisibleStates[0], isDarkMode)}>
    <div class="text-5xl">Fachbücher SW/HW</div>
    <hr class="border-t-2 border-gray-300 my-4"/>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Autor
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Titel
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Jahr
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Patrick Schnabel </th>
                      <td class="px-6 py-4">
                        Elektronik-Fibel </td>
                      <td class="px-6 py-4">
                        2017 </td>
                  </tr>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Robert C. Martin </th>
                      <td class="px-6 py-4">
                        Clean-Code </td>
                      <td class="px-6 py-4">
                        2008 </td>
                  </tr>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Robert C. Martin</th>
                      <td class="px-6 py-4">
                        The Clean Coder </td>
                      <td class="px-6 py-4">
                        2011 </td>
                  </tr>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Robert C. Martin </th>
                      <td class="px-6 py-4">
                        Clean Architecture </td>
                      <td class="px-6 py-4">
                        2017 </td>
                  </tr>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Thomas H. Cormen, Charles E. Leiserson, Ronald L. Rivest, Clifford Stein </th>
                      <td class="px-6 py-4">
                        Introduction to Algorithms, 3rd Edition </td>
                      <td class="px-6 py-4">
                        2009 </td>
                  </tr>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Howard Johnson, Martin Graham </th>
                      <td class="px-6 py-4">
                        High Speed Digital Design: A Handbook of Black Magic </td>
                      <td class="px-6 py-4">
                        1993 </td>
                  </tr>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Martin Fowler</th>
                      <td class="px-6 py-4">
                      Refactoring: Improving the Design of Existing Code </td>
                      <td class="px-6 py-4">
                        2018 </td>
                  </tr>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      David Thomas, Andrew Hunt </th>
                      <td class="px-6 py-4">
                        The Pragmatic Programmer</td>
                      <td class="px-6 py-4">
                        2019 </td>
                  </tr>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Steve McConnell </th>
                      <td class="px-6 py-4">
                      Code Complete 2nd Edition </td>
                      <td class="px-6 py-4">
                        2005 </td>
                  </tr>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Michael Jay Geier </th>
                      <td class="px-6 py-4">
                        How to Diagnose and Fix Everything Electronic</td>
                      <td class="px-6 py-4">
                        2015 </td>
                  </tr>
              </tbody>
          </table>
      </div>

    </div>

    <div ref={refs[1]} class={div1(isVisibleStates[1], isDarkMode)}>
    <div class="text-5xl">Sonstige Fachbücher</div>
    <hr class="border-t-2 border-gray-300 my-4"/>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Autor
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Titel
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Jahr
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      David Huron </th>
                      <td class="px-6 py-4">
                      Voice Leading: The Science behind a Musical Art</td>
                      <td class="px-6 py-4">
                        2016 </td>
                  </tr>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Dmitri Tymoczko </th>
                      <td class="px-6 py-4">
                      A Geometry of Music: Harmony and Counterpoint in the Extended Common Practice </td>
                      <td class="px-6 py-4">
                        2011 </td>
                  </tr>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Arnold Schoenberg</th>
                      <td class="px-6 py-4">
                      Fundamentals of Musical Composition </td>
                      <td class="px-6 py-4">
                        1967 </td>
                  </tr>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Julius Loewenstein </th>
                      <td class="px-6 py-4">
                      Rhetorik | Smalltalk | Schlagfertigkeit | Selbstbewusstein </td>
                      <td class="px-6 py-4">
                        2019 </td>
                  </tr>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Lajos Egri </th>
                      <td class="px-6 py-4">
                      The Art of Dramatic Writing </td>
                      <td class="px-6 py-4">
                        1946 </td>
                  </tr>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Orson Scott Card</th>
                      <td class="px-6 py-4">
                      How to Write Science Fiction and Fantasy</td>
                      <td class="px-6 py-4">
                        1990 </td>
                  </tr>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      James Clear</th>
                      <td class="px-6 py-4">
                      Atomic Habits </td>
                      <td class="px-6 py-4">
                        2018 </td>
                  </tr>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Morgan House </th>
                      <td class="px-6 py-4">
                      The Psychology of Money</td>
                      <td class="px-6 py-4">
                        2020 </td>
                  </tr>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 border-gray-200">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Wolfgang Baur et al. </th>
                      <td class="px-6 py-4">
                      Kobold Guide to Worldbuilding </td>
                      <td class="px-6 py-4">
                        2012 </td>
                  </tr>
              </tbody>
          </table>
        </div>
    </div>

    <div ref={refs[2]} class={div1(isVisibleStates[2], isDarkMode)}>
      <div class="text-5xl">Lesezeichen</div>
      <hr class="border-t-2 border-gray-300 my-4"/>
      <ul>
      <li><a href="http://www.w3schools.com">W3Schools</a></li>
      <li><a href="http://www.htl-salzburg.ac.at">HTL Salzburg</a></li>
      <li><a href="https://gitlab.com/SamuelEliasW">Gitlab</a></li>
      <li><a href="https://fh-ooe.at/campus-hagenberg">FH Hagenberg</a></li>
      <li><a href="https://www.debuggingbook.org/">Debugging Book</a></li>
      </ul>
    </div>
    </>
  );
  }
  
  export default Books;
  