import { useDarkMode } from '../DarkModeContext';
import useIsVisible from '../isVisible';
import { useRef, useEffect, useState} from "react";
import {MathJax, MathJaxContext} from "better-react-mathjax";

function MathLib() {
  const { isDarkMode } = useDarkMode();
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  const refs = [useRef(), useRef(), useRef(), useRef()];
  const isVisibleStates = 
    [ 
      useIsVisible(refs[0]), 
      useIsVisible(refs[1]), 
      useIsVisible(refs[2]), 
      useIsVisible(refs[3]), 
    ];

  const commonStyles = `flex-1 px-8 py-4 transition-opacity ease-in duration-700`;
  
  const div1 = (isVisible, isDarkMode) => `
    ${commonStyles} 
    ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'} 
    ${isVisible ? 'opacity-100' : 'opacity-0'}
  `;

  useEffect(() => {
    // Dynamically load the external script
    const script = document.createElement("script");
    script.src = "https://pyscript.net/releases/2025.2.2/core.js"; // URL for the external JS file
    script.crossOrigin = "anonymous";
    script.type = "module";               // Add this to load as ES module
    script.onload = () => {
      setIsScriptLoaded(true); // Set state to true once the script is loaded
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/symbolicMath.js";  // Adjust path if needed
    script.async = true;
    script.crossOrigin = "anonymous"; 
    script.onload = () => {
      console.log('symbolicMath.js loaded successfully');
      setIsScriptLoaded(true); 
    };
    script.onerror = () => {
      console.error('Failed to load symbolicMath.js');
    };
    
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleInputChange = (input, output) => {
    // Wait for the script to load before calling the function
    if (isScriptLoaded && window.getFunction) {
      console.log(`Handling input change for ${output}:`, document.getElementById(input).value);
      window.getFunction(output, input, "printEquation");
    }
  };

  const handleChangePar = (e) => {
    // Wait for the script to load before calling the function
    if (isScriptLoaded && window.changeParameter) {
      window.changeParameter(e.target);
    }
  };

  const handleSolve = (input, output) => {
    if (isScriptLoaded && window.getFunction) {
      console.log(`Handling input change for ${output}:`, document.getElementById(input).value);
      window.getFunction(output, input, "solveEquation");
    }
  };

  const handleSimplify = (input, output) => {
    if (isScriptLoaded && window.getFunction) {
      console.log(`Handling input change for ${output}:`, document.getElementById(input).value);
      window.getFunction(output, input, "simplify");
    }
  };

  const handlePlot = (inputID, StartID, EndID, StepID) => {
    if (isScriptLoaded && window.getPlot) {
      console.log(`Plotting result:`);
      window.getPlot(inputID, StartID, EndID, StepID);
    }
  };

  return (
    <>
      <link rel="stylesheet" href="https://pyscript.net/releases/2025.2.2/core.css" crossOrigin="anonymous"/>

      <script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.5.0/Chart.min.js"/>
     
      <py-config>
        packages = [
          "sympy",
          "numpy"
        ]
      </py-config>
      <py-script src="/symbolicMath.py"/>
      <div className="flex flex-row">
        <div className="w-2/3">
          <div ref={refs[0]} className={div1(isVisibleStates[0], isDarkMode)}>
          <div className="text-5xl">Symbolische Mathematik in Python</div>
          <hr className="border-t-2 border-gray-300 my-4" />
          <div className = "flex flex-row">
            <details className="rounded-md  py-2 px-4 border border-transparent text-sm  transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none m-2">
              <summary className="text-5xl">Verwendung</summary>
              Es wird zuerst eine Funktion eingegeben, die Berechnung erfolgt nach 'x'.<br/>
                          Das Programm wird betrieben durch Mathjax (Latex für HTML) und Sympy (symbolische Bibliothek für Python)<br/>
                          Mit Rechtsklick auf die Gleichung/"Show Math As" kann die Gleichung in MathML bzw Latex Format kopiert werden<br/>
            </details>
            <details className="rounded-md  py-2 px-4 border border-transparent text-sm  transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none m-2">
              <summary className="text-5xl">Info und Bugs von Simplify</summary>
              Sympy löst Integrale sowie Differnziale automatisch auf, somit liefert "Simplify" oder "solve" true bzw eine leere Menge zurück.<br/>
                          Ebenso können einige Funktionen nicht ausgegeben werden, da simplify nicht dafür konzipiert worden ist absolute Werte zu berechnen.<br/><br/>

                          <b>Sympy Syntax:<br/></b>

                          x**y == x hoch y<br/>
                          diff(f,x) == f differenziert nach x<br/>
                          integrate(f,x) == f integriert nach x<br/>
                          log(e,2) == log2(e)<br/>
                          Matrix([[1,2],[2,3]]) == 2x2 Matrix<br/>
                          lim(f,x,0) == Welchen Wert nimmt f an, wenn x nach 0 geht?<br/>
                          det(a) == Determenante der Matrix a<br/><br/>
                          
                          Sympy versteht auch unterschiedliche Begriffe wie collect usw., diese können auf ihre Website nachgeschlagen und angewendet werden<br/>
                          Um Kommazahlen als Ergebniss zu erhalten, muss einer der Zahlen als Komazahl eingegeben werden z.B. '5.0'<br/>
            </details>
          </div>
        </div>

        
        <div ref={refs[1]} className={div1(isVisibleStates[1], isDarkMode)}>
          <div className="text-5xl">Solving Equations</div>
          <hr className="border-t-2 border-gray-300 my-4" />
          <MathJaxContext>
              <input id="input"
              defaultValue="a*x**2+b*x+c=0" onChange={() => handleInputChange('input', 'outputEQ')}  className="bg-gray-400"/>
              <button id="calc_btn" className="rounded-md px-4 py-2 bg-gray-400 m-4 text-center hover:bg-opacity-30 hover:bg-white transition-all duration-200" onClick={() => handleSolve('input', 'output')}>
                  solve Equation
              </button>
              <button id="simplify_btn" className="rounded-md px-4 py-2 bg-gray-400 m-4 text-center hover:bg-opacity-30 hover:bg-white transition-all duration-200" onClick={() => handleSimplify('input', 'output')}>
                  simplify
              </button>
              <br/>
              Equation:
              <MathJax id="outputEQ">$$a x^{2} + b x + c = 0$$</MathJax>
              Solution:
              <MathJax id="output">
                $$
                  x = 2 a
                $$
              </MathJax>
            </MathJaxContext>
            <br/>

            <input id="inputPlot"
            defaultValue="f" className="bg-gray-400" onChange={() => handleInputChange('inputPlot', 'outputPlot')}/>
            <button id="plot_btn" className="rounded-md  py-2 px-4 border border-transparent text-sm  
            transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 
            hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 
            disabled:shadow-none m-2" onClick={() => handlePlot('inputPlot', 'start', 'end', 'step')}>
                plot function
            </button>
            <br/>
            <div className="flex;">
                <div >
                    Start:<br/>
                    <input id="start" className="bg-gray-400" type="number" defaultValue="-10"/>
                </div>
                <div >
                    end:<br/>
                    <input id="end" className="bg-gray-400" type="number" defaultValue="10" />
                </div>
                <div >
                    steps:<br/>
                    <input id="step" className="bg-gray-400" type="number" defaultValue="0.1" />
                </div>
            </div>
            <div id="outputPlot">$$sin(x)$$</div>
            
            <br/>
        </div>

        <div ref={refs[2]} className={div1(isVisibleStates[2], isDarkMode)}>
          <div>
              <canvas id="myChart"></canvas>
          </div>
        </div>
        </div>
        <div className="w-1/3">
          <div ref={refs[3]} className={div1(isVisibleStates[3], isDarkMode)}>
            <b>Parameter / Functions</b>
            <br/>
            a=<input defaultValue="a" id="a" className="w-[95/100] bg-gray-400" onChange={(e) => handleChangePar(e)}/><br/>
            b=<input defaultValue="b" id="b" className="w-[95/100] bg-gray-400" onChange={(e) => handleChangePar(e)}/><br/>
            c=<input defaultValue="c" id="c" className="w-[95/100] bg-gray-400" onChange={(e) => handleChangePar(e)}/><br/>
            d=<input defaultValue="d" id="d" className="w-[95/100] bg-gray-400" onChange={(e) => handleChangePar(e)}/><br/>
            k=<input defaultValue="k" id="k" className="w-[95/100] bg-gray-400" onChange={(e) => handleChangePar(e)}/><br/>
            p=<input defaultValue="p" id="p" className="w-[95/100] bg-gray-400" onChange={(e) => handleChangePar(e)}/><br/>
            q=<input defaultValue="q" id="q" className="w-[95/100] bg-gray-400" onChange={(e) => handleChangePar(e)}/><br/>
            x=<input defaultValue="x" id="x" className="w-[95/100] bg-gray-400" onChange={(e) => handleChangePar(e)}/><br/>
            y=<input defaultValue="y" id="y" className="w-[95/100] bg-gray-400" onChange={(e) => handleChangePar(e)}/><br/>
            f=<input defaultValue="sin(x)" id="f" className="w-[95/100] bg-gray-400" onChange={(e) => handleChangePar(e)}/><br/>
            g=<input defaultValue="g" id="g" className="w-[95/100] bg-gray-400" onChange={(e) => handleChangePar(e)}/><br/>
        </div>
        </div>
      </div>
      

    </>
  );
}

export default MathLib;
