import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Foto3 from './pic/BassTool.jpeg'
import Foto1 from './pic/CampinaBandKonzert.jpg';
import Foto2 from './pic/Samuel.png';

function Slideshow() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="max-w-lg mx-auto p-4">
      <Slider {...settings}>
        <div className="flex items-center justify-center h-[500px]">
          <div className="relative w-full h-full flex items-center justify-center">
            <img src={Foto2} alt="Slide 1" className="max-h-full"/>
          </div>
        </div>
        <div className="flex items-center justify-center h-[500px]">
          <div className="relative w-full h-full flex items-center justify-center">
            <img src={Foto3} alt="Slide 2" className="max-h-full"/>
          </div>
        </div>
        <div className="flex items-center justify-center h-[500px]">
          <div className="relative w-full h-full flex items-center justify-center">
            <img src={Foto1} alt="Slide 3" className="max-h-full"/>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default Slideshow;