import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from 'react';
import './index.css';
import Projects from "./pages/Projects";
import Books from "./pages/Books";
import Homepage from "./pages/Homepage";
import Bubblesort from "./pages/bubblesort";
import Quicksort from "./pages/quicksort";
import Heapsort from "./pages/heapsort";
import MathLib from "./pages/Math";
import Layout from "./pages/Layout";
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Homepage />} />
        <Route path="projects" element={<Projects />} />
        <Route path="books" element={<Books />} />
        <Route path="bubblesort" element={<Bubblesort />} />
        <Route path="heapsort" element={<Heapsort />} />
        <Route path="quicksort" element={<Quicksort />} />
        <Route path="python" element={<MathLib />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
