import { useDarkMode } from '../DarkModeContext';
import useIsVisible from '../isVisible';
import { useRef, useEffect, useState } from "react";
import Chart from 'chart.js/auto';

function Bubblesort() {
  const { isDarkMode } = useDarkMode();

  const refs = [useRef()];
  const isVisibleStates = [useIsVisible(refs[0])];
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [arr, setArr] = useState([]);

  const commonStyles = `flex-1 px-8 py-4 transition-opacity ease-in duration-700`;

  const div1 = (isVisible, isDarkMode) => `
    ${commonStyles} 
    ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'} 
    ${isVisible ? 'opacity-100' : 'opacity-0'}
  `;
  var item = "text-center flex-1 px-8 py-4 hover:bg-pink-800 hover:bg-opacity-30 hover:bg-white transition-all duration-200";

  useEffect(() => {
    generateNewArray();
  }, []);

  function generateNewArray() {
    const newArr = Array.from({ length: 50 }, () => Math.floor(Math.random() * 100));
    setArr(newArr);
    updateChart(newArr);
  }

  function updateChart(data) {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    if (chartRef.current) {
      const points = data.map((_, index) => index);
      chartInstance.current = new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels: points,
          datasets: [{
            backgroundColor: 'black',
            data: data,
          }],
        },
        options: {
          plugins: {
            legend: { display: false },
            title: { display: true, text: 'Bubblesort' },
          },
          animation: { duration: 0 },
        },
      });
    }
  }

  async function bubblesort() {
    let arrayCopy = [...arr];
    let swapped;
    do {
      swapped = false;
      for (let i = 0; i < arrayCopy.length - 1; i++) {
        if (arrayCopy[i] > arrayCopy[i + 1]) {
          [arrayCopy[i], arrayCopy[i + 1]] = [arrayCopy[i + 1], arrayCopy[i]];
          swapped = true;
          updateChart(arrayCopy);
          await new Promise((resolve) => setTimeout(resolve, 10));
        }
      }
    } while (swapped);
  }

  return (
    <>
      <div ref={refs[0]} className={div1(isVisibleStates[0], isDarkMode)}>
        <div className="text-5xl">Bubble-Sort</div>
        <hr className="border-t-2 border-gray-300 my-4" />
        <div className="flex flex-col">
          <button id="createArrayBtn" onClick={generateNewArray} className={item}>Neues Array</button>
          <button id="bubblesortBtn" onClick={bubblesort} className={item}>Sortieren</button>
          <div style={{ width: "80%", margin: "auto" }}>
            <canvas ref={chartRef} id="myChart"></canvas>
          </div>
        </div>
      </div>
    </>
  );
}

export default Bubblesort;