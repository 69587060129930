import { useDarkMode } from '../DarkModeContext';
import useIsVisible from '../isVisible';
import { useRef, useEffect, useState } from "react";
import Chart from 'chart.js/auto';

function Heapsort() {
  const { isDarkMode } = useDarkMode();

  const refs = [useRef()];
  const isVisibleStates = [useIsVisible(refs[0])];
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [arr, setArr] = useState([]);

  const commonStyles = `flex-1 px-8 py-4 transition-opacity ease-in duration-700`;

  const div1 = (isVisible, isDarkMode) => `
    ${commonStyles} 
    ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'} 
    ${isVisible ? 'opacity-100' : 'opacity-0'}
  `;
  var item = "text-center flex-1 px-8 py-4 hover:bg-pink-800 hover:bg-opacity-30 hover:bg-white transition-all duration-200";

  useEffect(() => {
    generateNewArray();
  }, []);

  function generateNewArray() {
    const newArr = Array.from({ length: 250 }, () => Math.floor(Math.random() * 100));
    setArr(newArr);
    updateChart(newArr);
  }

  function updateChart(data) {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    if (chartRef.current) {
      const points = data.map((_, index) => index);
      chartInstance.current = new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels: points,
          datasets: [{
            backgroundColor: 'black',
            data: data,
          }],
        },
        options: {
          plugins: {
            legend: { display: false },
            title: { display: true, text: 'Heapsort' },
          },
          animation: { duration: 0 },
        },
      });
    }
  }

  async function heapSort(array) {
    let n = array.length;
    for (let i = Math.floor(n / 2) - 1; i >= 0; i--) {
      await maxHeapify(array, n, i);
    }
    for (let i = n - 1; i > 0; i--) {
      [array[0], array[i]] = [array[i], array[0]];
      updateChart([...array]);
      await new Promise(resolve => setTimeout(resolve, 1));
      await maxHeapify(array, i, 0);
    }
  }

  async function maxHeapify(array, n, i) {
    let largest = i;
    let l = 2 * i + 1;
    let r = 2 * i + 2;

    if (l < n && array[l] > array[largest]) largest = l;
    if (r < n && array[r] > array[largest]) largest = r;

    if (largest !== i) {
      [array[i], array[largest]] = [array[largest], array[i]];
      updateChart([...array]);
      await new Promise(resolve => setTimeout(resolve, 1));
      await maxHeapify(array, n, largest);
    }
  }

  async function startHeapsort() {
    let arrayCopy = [...arr];
    await heapSort(arrayCopy);
    setArr(arrayCopy);
  }

  return (
    <>
      <div ref={refs[0]} className={div1(isVisibleStates[0], isDarkMode)}>
        <div className="text-5xl">Heap-Sort</div>
        <hr className="border-t-2 border-gray-300 my-4" />
        <div className="flex flex-col">
          <button id="createArrayBtn" onClick={generateNewArray} className={item}>Neues Array</button>
          <button id="heapsortBtn" onClick={startHeapsort} className={item}>Sortieren</button>
          <div style={{ width: "80%", margin: "auto" }}>
            <canvas ref={chartRef} id="myChart"></canvas>
          </div>
        </div>
      </div>
    </>
  );
}

export default Heapsort;