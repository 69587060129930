import { useDarkMode } from '../DarkModeContext';
import useIsVisible from '../isVisible';
import { useRef } from "react";

import VideoConsole1 from '../pic/VideoGameConsole.jpg'
import VideoConsole2 from '../pic/VideoGameConsole2.jpg'

import Server1 from '../pic/ServerApp1.jpg'
import Server2 from '../pic/ServerApp2.jpg'
import Server3 from '../pic/ServerApp3.jpg'


import BaBo1 from '../pic/Babo1.jpg'
import BaBo2 from '../pic/Babo2.jpg'
import BaBo3 from '../pic/BaBo_Schaltplan.png'

import Schmu1 from '../pic/SchmuBuddy1.jpeg'
import Schmu2 from '../pic/SchmuBuddy2.PNG'

import Druck1 from '../pic/3DDruck1.jpg'
import Druck2 from '../pic/3DDruck2.jpg'
import Druck3 from '../pic/3DDruck3.jpg'
import Druck4 from '../pic/3DDruck4.jpg'

import Sig1 from '../pic/SignalGen1.jpg'
import Sig2 from '../pic/SignalGen2.jpg'
import Sig3 from '../pic/SignalGen3.JPG'

import DM1 from '../pic/DMScreen.JPG'
import DM2 from '../pic/DMScreen2.jpg'

function Projects() {
  
    const { isDarkMode } = useDarkMode();

    const refs = [useRef(), useRef()];
    const isVisibleStates = 
      [ 
      useIsVisible(refs[0]),
      useIsVisible(refs[1]),
      ];
    
    const commonStyles = `flex-1 px-8 py-4 transition-opacity ease-in duration-700`;

    const div1 = (isVisible, isDarkMode) => `-
      ${commonStyles} 
      ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'} 
      ${isVisible ? 'opacity-100' : 'opacity-0'}
    `;

    return (
    <>
      <div ref={refs[0]} class={div1(isVisibleStates[0], isDarkMode)}>
      <div class="text-5xl">Meine Projekte</div>
      <hr class="border-t-2 border-gray-300 my-4"/>
      In diesem Abschnitt finden Sie Informationen zu Projekten, welche ich in meiner
      Freizeit gefertigt habe.
      </div>

      <div ref={refs[1]} class={div1(isVisibleStates[1], isDarkMode)}>
        <details class="rounded-md  py-2 px-4 border border-transparent text-sm  transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none m-2">
          <summary class="text-5xl">Videospiel-Konsole</summary>
          <p>Ich pflegte schon immer eine Faszination zu Videospielen, insbesondere die Geschichte dahinter, welche sich so schnell weiterentwickelte</p>
          <p>Für mich gab es keine Möglichkeit, außer meines Home-PCs, meine Spiele von überall aus zu spielen.</p>
          <p>Die Idee hinter dem Projekt war nun das Projekt, welches exzellent auf Thingiverse beschrieben wurde, zu erweitern und eine Anbindung</p>
          <p>an meinen Server zu erlauben.</p>
          <p>Das Design wurde aus <a href="https://www.thingiverse.com/thing:2911881">Thingiverse</a> entnommen und mit dem Geeetech A10 (3D-Drucker) gedruckt.</p>
          <p>Die Website enthält ein Tutorial über den gesamten Aufbau einer Konsole, doch da einige Teile sehr teuer waren wurden die Komponenten selbst ausgewählt und auf das</p>
          <p>Gehäuse angepasst.</p>
          <p>Die Knöpfe wurden eigens über ein Arduino Nano Board programmiert.</p>
          <p>Versorgt wird das Projekt mit 2 LiPo-Zellen, welche über eine Sicherungsbeschaltung mit dem Rpi verbunden sind. Der Lautsprecher wurde über einen Verstärker direkt mit dem RPI verbunden</p>
          <p>Der schwierigste Teil war es einen Streamserver über <a href="https://moonlight-stream.org">Moonlight</a> einzurichten, sodass die Konsole sich mit dem eigenen PC
            verbinden konnte und die Spiele über Internet zu übertragen.</p>
          <p>Es wurde dazu ein Skript geschrieben, welches vom Betriebssystem Retropie ausgelesen und ausgeführt werden kann.</p>
        
          <div class="flex flex-row">
            <img src={VideoConsole1} class="w-1/2"/>
            <img src={VideoConsole2} class="w-1/2"/>
          </div>
        </details>

        <details class="rounded-md py-2 px-4 border border-transparent text-sm transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none m-2">
          <summary class="text-5xl">Server und Anstererung</summary>
          <p>Die Idee hinter diesem Projekt war, mich unabhängiger von Online-Diensten wie VPN- und Cloud-Servicen zu machen.</p>
          <p>Diesbezüglich verwendete ich einen Raspberry Pi 4, denn ich mit Firewalls und Port-Forwarding über das Internet erreichbar machte.</p>
          <p>Zuerst habe ich den Webserver gescriptet. Später einen Cloud-, VPN-, Gamestreaming- and SSH/SFTP-Server.</p>
          <p>Um nun die Ansteruerng zu erleichtern wurde eine App geschrieben, welche sich automatisch über SSH mit dem Server verbindet.</p>
          <p>Die Anmeldedaten werden über AES256 verschlüsselt.</p>
          <p>Es werden sämtliche vorgefertigte Bash-Skripten auf die App geladen, um automatisierte Aufgaben zu erledigen.</p>
          <p>Zusätzlich kann der Server direkt über ein minimalistisches Terminal angesteuert werden.</p>
          <p>Nachdem die AES256-Verschlüsselung bereits für die Anmeldedaten verwendet wird wurde zusätzlich ein PWD-Manager geschrieben,</p>
          <p>welcher private Passwörter am Gerät verschlüsselt speichern kann.</p>
          
          <div class="flex flex-row">
            <img src={Server1} class="w-1/3 m-1"/>
            <img src={Server2} class="w-1/3 m-1"/>
            <img src={Server3} class="w-1/3 m-1"/>
          </div>
        </details>

        <details class="rounded-md  py-2 px-4 border border-transparent text-sm  transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none m-2">
          <summary class="text-5xl">BastlerBoard</summary>
          <p>Das BastlerBoard (oder BaBo) ist ein eigenes Development Board, das über die Arduino IDE programmiert werden kann.</p>
					<p>Das Projekt wurde im Zuge eines HTL-Projektes mit meinen Kollegen Daniel Waltl abgeschlossen.</p>
					<p>Über die Digital- beziehungsweise Analog-Pins können Schaltungen oder Sensoren angeschlossen werden, die dann über die Software angesteuert werden.</p>
					<p>Über einen eingebauten „USB to UART“-Converter kann der Mikrocontroller mit USB-Mikro programmiert werden.</p>

					<br/>

					<p>Der Mikrocontroller besitzt einen eingebauten Buzzer und einen Temperatursensor.</p>
					<p>Es wird ein externer Oszillator verwendet der die Taktfrequenz setzt.</p>
					<p>Ebenso sind zwei 7-Segement Anzeigen implementiert mit denen man numerische Werte ausgeben lassen kann.</p>
					<p>Auf dem Board befindet sich ein DIP-Switch der als interner Schalter für die Sensoren geschaltet wird.</p>
					
					<br/>
					<p>Als Spannungsversorgung dient der USB-Mikro mit dem ebenfalls programmieren wird.</p>
					<p>Durch einen Spannungsregler wird die 3,3V Spannung erzeugt, die für Sensoren benötigt wird.</p>
          
          <div class="flex flex-row">
            <img src={BaBo1} class="w-1/3 m-1"/>
            <img src={BaBo2} class="w-1/3 m-1"/>
            <img src={BaBo3} class="w-1/3 m-1"/>
          </div>
        </details>

        <details class="rounded-md  py-2 px-4 border border-transparent text-sm  transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none m-2">
          <summary class="text-5xl">Schmu-Buddy</summary>
          <p>Um meine Kenntnisse in der Analog-Digital-Welt zu verbessern entschied ich mich</p>
          <p>eine analog Schaltung zu bauen, welches das Signal eines Basses/einer Gitarre</p>
          <p>abnehmen und digitalisieren kann.</p>
          <p>Dazu wird ein Arduino Nano mit einer FFT verwendet.</p>
          <p>Der erste Prototyp ist direkt verbindbar mit der Gitarre, der Stecker</p>
          <p>wird direkt weitergeben, sodass keine Störungen durch das Produkt entstehen.</p>
          <p>Die analoge Schaltung verstärkt das Signal so, dass es sich zwischen 0 und 5V befindet.</p>
          <p>Dies ist nötig, sodass der ADC sein volles Spektrum verwenden kann.</p>
          
          <div class="flex flex-row">
            <img src={Schmu1} class="w-1/2 h-1/2"/>
            <img src={Schmu2} class="w-1/2 h-1/2 self-center"/>
          </div>
        </details>

        <details class="rounded-md  py-2 px-4 border border-transparent text-sm  transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none m-2">
          <summary class="text-5xl">Latex Vorlage für Software-Programmierung</summary>
          <p>Um meine Software-Dokumentation so effizient wie möglich zu gestalten entwarf ich über</p>
          <p>Latex eine Vorlage, welche die meisten Aufgaben automatisieren kann.</p>
          <p>Aufgaben, welche automatisiert werden sollten: </p>
          <p>- Automatische Formatierung</p>
          <p>- Code-Implementierung mit Syntax-Highlighting</p>
          <p>- leichten Einfügen von Text-Files und PDF-Files.</p>
          <p>- schnelles Design, ohne viel über Format nachzudenken.</p>
          <a href="https://gitlab.com/SamuelEliasW/latex-template/-/tree/main">Link zum Projekt</a>
        </details>

        <details class="rounded-md  py-2 px-4 border border-transparent text-sm  transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none m-2">
          <summary class="text-5xl">3D-Druck Projekte</summary>
          
          <div class="flex flex-row">
            <img src={Druck1} class="w-1/4"/>
            <img src={Druck2} class="w-1/4"/>
            <img src={Druck3} class="w-1/4"/>
            <img src={Druck4} class="w-1/4"/>
          </div>
        </details>

        <details class="rounded-md  py-2 px-4 border border-transparent text-sm  transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none m-2">
          <summary class="text-5xl">Signalgenerator mit STM32F071</summary>
          <p>Da ich viel mit Audio-Technik mache brauche ich eine Möglichkeit, um meine Geräte testen zu können.</p>
          <p>Als Projekt nahm ich und meine Freundin Erin Sonnleitner sich vor, aus einem Mikrocontroller einen eigenen Signalgenerator zu basteln</p>
          <p>Dabei musste eine Frequenz von 20kHz erreicht werden und dabei die Oberfrequenzen, die durch den DAC entstehen so gering</p>
          wie möglich gehalten werden.
          <br/>
          <p>Um den Sinus-Table zu berechnen wurde ein Programm für den Arduino geschrieben, welcher einen Table erzeugt mit</p>
          variabler Länge
          <br/>
          <p>Über einen Timer kann die Frequenz eingestellt werden, bei einem Overflow wird der DMA kontaktiert, welcher</p>
          <p>den nächsten Eintrag in der Sinus/Rechteck-Table an den DAC schickt.</p>
          <p>Die gesamte Generierung des Signales wird mit der Peripherie ausgewertet, dadurch kann der Main-Prozessor</p>
          <p>rein für die Auswertung der Knöpfe und Potis verwendet werden.</p>
          <p>Um eine Anzeige ermöglichen zu können musste eine Anbindung zu einem 16x2 LCD-Panel erstellt werden.</p>
          <p>Über das Datenblatt konnte die notwendige Initialierung entnommen werden.</p>
          <p>Die maximal erreichbare Frequenz ist 23kHz, wobei die Oberfrequenzen bei -50dB liegen.</p>
          <p>Würde man die Punkte er Sinus-Table auf 2 legen und einen TP verwenden, könnte man sogar eine Frequenz</p>
          <p>von 2MHz erreichen, doch dabei würden die Oberfrequenzen stark beeinflußen können und nachdem</p>
          <p>die Anforderung nur 20kHz war (Audio-Bereich) wurde dies nicht implementiert.</p>
			
          
          <div class="flex flex-row">
            <img src={Sig1} class="w-1/3"/>
            <img src={Sig2} class="w-1/3"/>
            <img src={Sig3} class="w-1/3"/>
          </div>
        </details>

        <details class="rounded-md  py-2 px-4 border border-transparent text-sm  transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none m-2">
          <summary class="text-5xl">DM-Screen</summary>
            <p>In meiner Freizeit spiele ich gerne mit meinen Freunden ein Tabletop-RPG-Spiel namens Dungeons and Dragons.</p>
            <p>Dieses Spiel erfordert sehr viel Multitasking (als Dungeon Master) und somit hat man oft 20 Tabs im Browser offen</p>
            <p>um alles im Spiel tracken zu können.</p>
            <br/>
            <p>Um dies nun zu automatisiern wurden die wichtigsten Elemente herausgesucht und in einer Anwendung implemntiert</p>
            <p>Dies wären: Initiative-Tracker (für Kämpfe), digitale Notizen, Taschenrechner für komplexe Berechnung, automatische
              Musikauswahl, Event-Generierung, PDF-Anzeige für vorgefertige Notizen
            </p>
            <p>Zusätzlich wurde ein RGB-LED Streifen mit einem Arduino verwendet, um die Beleuchung</p>
            <p>von der Musik abhängen zu lassen. Der Screen selbst wurde mit dem Lasercutter gefertigt</p>
            <p>Im Foto Ich und David Muttenthaler, welcher ein ähnliches Projekt hatte.</p>
            <p>Gelötet und zusammengebaut mit Erin Sonnleitner</p>
          
          <div class="flex flex-row">
            <img src={DM1} class="w-1/2"/>
            <img src={DM2} class="w-1/2 h-1/2 self-center"/>
          </div>
        </details>

        <details class="rounded-md  py-2 px-4 border border-transparent text-sm  transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none m-2">
          <summary class="text-5xl">Solar Navigator</summary>
          <a href="https://gitlab.com/SamuelEliasW/projects_weis/-/tree/master/Solar_Navigator">Link zum Projekt</a>
          <p>Um mich in der GUI-Programmierung weiterzubilden, programmierte ich eine Simulation des Sonnensystems.</p>
          <p>Dabei kalkulierte ich für jeden Planeten eine Funkion, die abhängig von der Siderischen Periode des jeweiligen Planeten ist.</p>
          <p>Das Projekt wurde 2017 gestartet und wurde in Java programiert.</p>
          <p>2022 wurde ein Refactoring durchgeführt, welches über OOP und Design-Patterns fast 70% des Codes einsparen konnte, doch</p>
          <p>im Zuge dessen fand ich heraus, dass die Daten, welche im Internet über die siderische Periode angegeben sind, um 2% bis 4% pro Jahr abweichen.</p>
          <p>Des führt im Program dazu, dass die Daten immer ungenauer werden, nachdem dieser Prozentssatz sich immer ändert.</p>
          <p>Nachdem keine Alternative gefunden wurde, wurde das Projekt eingestellt.</p>
        </details>

        <details class="rounded-md  py-2 px-4 border border-transparent text-sm  transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none m-2">
          <summary class="text-5xl">TCP-Client</summary>
          <a href="https://gitlab.com/SamuelEliasW/projects_weis/-/tree/master/TCPClient">Link zum Projekt</a>
          <p>Dieses Projekt verbindet sich mit einem Server via TCP.</p>
          <p>Nach dem Aufbau einer Verbindung können Daten gesendet bzw. empfangen werden.</p>
          <p>Das Projekt wurde 2018 gestartet und in C# programmiert.</p>
        </details>

        <details class="rounded-md  py-2 px-4 border border-transparent text-sm  transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none m-2">
          <summary class="text-5xl">Learning-Tool</summary>
          <a href="https://gitlab.com/SamuelEliasW/projects_weis/-/tree/master/LearningTool">Link zum Projekt</a>
          <p>Um meinen Notenstand an der HTL zu verbessern entwickelte ich eine kleine Lernapp, in der ein Quiz erstellt werden kann.</p>
          <p>Die Daten werden in ein XML-File gespeichert und sortiert nach Fach und Thema.</p>
          <p>Mit dieser Lernapp konnte ich in theoretischen Tests besser abschneiden als sonst.</p>
          <p>Das Projekt wurde 2018 gestartet und in C# programmiert.</p>
        </details>
      </div>

    </>
      
    );
  }
  
  export default Projects;
  