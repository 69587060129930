import Slideshow from '../Slideshow'
import { useDarkMode } from '../DarkModeContext';
import useIsVisible from '../isVisible';
import { useRef } from "react";

function Homepage() {
    const { isDarkMode } = useDarkMode();

    
  const refs = [useRef(), useRef(), useRef()];
  const isVisibleStates = 
    [ 
    useIsVisible(refs[0]), 
    useIsVisible(refs[1]),
    useIsVisible(refs[2])
    ];

    const commonStyles = `flex-1 px-8 py-4 transition-opacity ease-in duration-700`;

    const div1 = (isVisible, isDarkMode) => `
      ${commonStyles} 
      ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'} 
      ${isVisible ? 'opacity-100' : 'opacity-0'}
    `;
    return (
        <div class="flex-column">
            <div class="flex-1 px-8 py-4 bg-gradient-to-r from-Template-1 to-Template-2">
                <Slideshow/>
            </div>

            <div class="flex flex-row">
                <div class="w-2/3">
                    <div ref={refs[0]} class={div1(isVisibleStates[0], isDarkMode)}>
                        <div class="text-5xl">Über mich - Elektronik</div>
                        <hr class="border-t-2 border-gray-300 my-4"/>
                        Meine Interesse für Software und Hardware entwickelte sich schon früh. Bereits in meiner Kindheit beschäftigte ich mich 
                        intensiv mit der Funktionsweise von Computern. Mit 10 Jahren programmierte ich mein erstes Spiel, 
                        das mit der GameMaker Engine entwickelt wurde. Dabei entdeckte ich meine Leidenschaft, komplexe 
                        Probleme so effizient und kreativ wie möglich zu lösen. Diese Erfahrung legte den Grundstein für 
                        meine weiterführende Beschäftigung mit verschiedenen Aspekten der Softwareentwicklung.<br/><br/>

                        Im Laufe der Jahre vertiefte ich mein Wissen und erlernte zunehmend fortgeschrittene 
                        Konzepte in der Programmierung, darunter Algorithmen, Design-Patterns und effiziente 
                        Implementierungen. Um meine Fähigkeiten weiter auszubauen, lernte ich verschiedene 
                        Programmiersprachen. Zuerst begleitete mich Java und C++, später kamen Sprachen wie 
                        C#, Python und Skriptsprachen wie JavaScript hinzu. Heute fühle ich mich in vielen 
                        Bereichen der Software- und Hardware-Entwicklung zu Hause, insbesondere in der 
                        App-Entwicklung, der Datenbankverwaltung und dem Umgang mit modernen 
                        Versionskontrollsystemen wie Git.<br/><br/>

                        Neben der Softwareentwicklung konnte ich auch wertvolle Erfahrungen in weiteren 
                        Bereichen wie Servermanagement, KI, Cybersicherheit und der Arbeit mit 
                        Entwicklungsboards sammeln. Besonders der Umgang mit verschiedenen 
                        Hardwareplattformen, vom einfachen Mikrocontroller bis hin zu komplexeren 
                        Embedded-Systemen, fasziniert mich und treibt mich an, immer neue Lösungen zu 
                        entwickeln und bestehende zu optimieren.<br/><br/>

                        Mit dieser breiten Wissensbasis und meiner Leidenschaft für innovative 
                        Technologien bin ich stets bestrebt, meine Fähigkeiten weiterzuentwickeln und neue 
                        Herausforderungen in der Welt der Software- und Hardwareentwicklung anzunehmen.
                    </div>

                    <div ref={refs[1]} class={div1(isVisibleStates[1], isDarkMode)}>
                        <div class="text-5xl">Über mich - Musik</div>
                        <hr class="border-t-2 border-gray-300 my-4"/>
                        Neben meinem Beruf widme ich mich mit Leidenschaft der Musik. Dabei habe ich mir umfangreiches Wissen in den Bereichen Musiktheorie, Harmonie-Lehre, Formenlehre und Kompositionslehre angeeignet. Zudem komponiere ich eigene Werke.
                    </div>

                    <div ref={refs[2]} class={div1(isVisibleStates[2], isDarkMode)}>
                        <div class="text-5xl">Andere Hobbys</div>
                        <hr class="border-t-2 border-gray-300 my-4"/>
                        <div class="flex mx-4">
                            <div class="border-l-2 border-dashed border-blue-200">
                                <div class="relative w-full">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-blue-500">
                                    <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                                </svg>
                                <div class="ml-6">
                                    <h4 class="font-bold text-blue-500">Musik</h4>
                                    <p class="mt-2 max-w-screen-sm text-sm">Gitarre</p>
                                    <p class="mt-2 max-w-screen-sm text-sm">Bass</p>
                                    <p class="mt-2 max-w-screen-sm text-sm">Keyboard</p>
                                    <p class="mt-2 max-w-screen-sm text-sm">Ocarina</p>
                                    <p class="mt-2 max-w-screen-sm text-sm">Komponieren</p>
                                </div>
                                </div>
                                <div class="relative w-full">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-blue-500">
                                    <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                                </svg>
                                <div class="ml-6">
                                    <h4 class="font-bold text-blue-500">Sport</h4>
                                    <p class="mt-2 max-w-screen-sm text-sm">Boxen</p>
                                    <p class="mt-2 max-w-screen-sm text-sm ">Tanzen</p>
                                    <p class="mt-2 max-w-screen-sm text-sm ">Bouldern</p>
                                    <p class="mt-2 max-w-screen-sm text-sm ">Berg gehen</p>
                                </div>
                                </div>
                                <div class="relative w-full">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-blue-500">
                                    <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                                </svg>
                                <div class="ml-6">
                                    <h4 class="font-bold text-blue-500">Lesen</h4>
                                    <p class="mt-2 max-w-screen-sm text-sm ">Fachliteratur HW/SW</p>
                                    <p class="mt-2 max-w-screen-sm text-sm ">Musik-Komposition/Harmonie-Lehre</p>
                                    <p class="mt-2 max-w-screen-sm text-sm ">Fiction: Science-Fiction, Fantasy</p>
                                </div>
                                </div>
                                <div class="relative w-full">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-blue-500">
                                    <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                                </svg>
                                <div class="ml-6">
                                    <h4 class="font-bold text-blue-500">Astronomie</h4>
                                </div>
                                </div>
                                <div class="relative w-full">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-blue-500">
                                    <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                                </svg>
                                <div class="ml-6">
                                    <h4 class="font-bold text-blue-500">Dungeon and Dragons</h4>
                                </div>
                                </div>
                            </div>
                            </div>
                    </div>
                </div>
                <div class="flex w-1/3">
                    <div class="flex flex-col gap-4 p-6 justify-center text-lg min-w-full">
                        <div
                            class="bg-gray-100 text-black border-l-8 border-blue-500 rounded-md px-3 py-2">
                            C++

                            <div class="bg-gray-300 text-black w-[85%]">85%</div>
                        </div>
                        <div
                            class="bg-gray-100 text-black border-l-8 border-blue-500 rounded-md px-3 py-2">
                            C#

                            <div class="bg-gray-300 text-black w-[80%]">80%</div>
                        </div>
                        <div
                            class="bg-gray-100 text-black border-l-8 border-blue-500 rounded-md px-3 py-2">
                            Javascript

                            <div class="bg-gray-300 text-black w-[75%]">75%</div>
                        </div>
                        <div
                            class="bg-gray-100 text-black border-l-8 border-blue-500 rounded-md px-3 py-2">
                            Haskell

                            <div class="bg-gray-300 text-black w-[75%]">75%</div>
                        </div>
                        <div
                            class="bg-gray-100 text-black border-l-8 border-blue-500 rounded-md px-3 py-2">
                            C

                            <div class="bg-gray-300 text-black w-[75%]">75%</div>
                        </div>
                        <div
                            class="bg-gray-100 text-black border-l-8 border-blue-500 rounded-md px-3 py-2">
                            Java

                            <div class="bg-gray-300 text-black w-[60%]">60%</div>
                        </div>
                        <div
                            class="bg-gray-100 text-black border-l-8 border-blue-500 rounded-md px-3 py-2">
                            VHDL

                            <div class="bg-gray-300 text-black w-[60%]">60%</div>
                        </div>
                        <div
                            class="bg-gray-100 text-black border-l-8 border-blue-500 rounded-md px-3 py-2">
                            Assembly

                            <div class="bg-gray-300 text-black w-[45%]">45%</div>
                        </div>
                        <div
                            class="bg-gray-100 text-black border-l-8 border-blue-500 rounded-md px-3 py-2">
                            Python

                            <div class="bg-gray-300 text-black w-[40%]">40%</div>
                        </div>
                        <div
                            class="bg-gray-100 text-black border-l-8 border-blue-500 rounded-md px-3 py-2">
                            SPS

                            <div class="bg-gray-300 text-black w-[25%]">25%</div>
                        </div>
                        <div
                            class="bg-gray-100 text-black border-l-8 border-blue-500 rounded-md px-3 py-2">
                            Haxe

                            <div class="bg-gray-300 text-black w-[15%]">15%</div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>  
    );
  }
  
  export default Homepage;
  